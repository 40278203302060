import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import deleteRequest from "@/plugins/requests/deleteRequest";

export default {
    actions: {
        fetchAdvertising(context) {
            return getRequests('/slayd_shows', 'updateAdvertising', context)
        },
        addAdvertising(context, data) {
            return postRequest('/slayd_shows', data, 'addAdvertisingMutation', context)
        },
        deleteAdverting(context, advertisingId) {
            return deleteRequest('/slayd_shows/' + advertisingId, 'deleteAdvertising', context)
        },
    },

    mutations: {
        updateAdvertising(state, advertising) {
            state.advertising = advertising
        },
        addProductMutation(){},
        addAdvertisingMutation(){},
        deleteAdvertising(){}
    },

    state: {
        advertising: {
            models: [],
            totalItems:0
        },
    },

    getters: {
        getAdvertising(state) {
            return state.advertising.models
        },
    }
}