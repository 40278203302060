import {createRouter, createWebHistory} from "vue-router/dist/vue-router"

const routes = [
    {
        path: '/',
        component: () => import('@/components/pages/MainPage.vue'),
    },
    {
        path: '/login',
        component: () => import('@/components/pages/RegistrationPage.vue'),
    },
    {
        path: '/orders',
        component: () => import('@/components/OrdersComponent.vue'),
    },
    {
        path: '/messengers',
        component: () => import('@/components/MessengersComponent.vue'),
    },
    {
        path: '/settings-menu',
        component: () => import('@/components/SettingsMenuComponent.vue'),
    },
    {
        path: '/settings-product/:menuId',
        component: () => import('@/components/SettingsProductComponent.vue'),
    },
    {
        path: '/report',
        component: () => import('@/components/ReportComponent.vue'),
    },
    {
        path: '/advertising',
        component: () => import('@/components/AdvertisingComponent.vue'),
    },

]

export default createRouter({
    history: createWebHistory(),
    // scrollBehavior() {
    //     window.scrollTo({top: 0, behavior: 'smooth'});
    // },
    routes
})
