import getRequests from "@/plugins/requests/getRequests";
// import postRequest from "@/plugins/requests/postRequest";
import putRequest from "@/plugins/requests/putRequest";

export default {
    actions: {
        fetchOrders(context) {
            return getRequests('/orders?status=0', 'updateOrders', context)
        },
        changeOrder(context, data) {
            return putRequest('/orders/' + data.id, data.order, 'updateChangeOrder', context)
        },
    },

    mutations: {
        updateOrders(state, orders) {
            state.orders = orders
        },
        updateChangeOrder(){}
    },

    state: {
        orders: {
            models: [],
            totalItems:0
        }
    },

    getters: {
        getOrders(state) {
            return state.orders.models
        }
    }
}
