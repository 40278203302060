import {createStore} from "vuex"
import menu from "@/plugins/vuex/modules/menu";
import user from "@/plugins/vuex/modules/user";
import mediaObject from "@/plugins/vuex/modules/mediaObject";
import statusModal from "@/plugins/vuex/statusModal";
import product from "@/plugins/vuex/modules/product";
import order from "@/plugins/vuex/modules/order";
import advertising from "@/plugins/vuex/modules/advertising";
export default createStore({
    modules: {
        user,
        menu,
        mediaObject,
        statusModal,
        product,
        order,
        advertising
    }
})